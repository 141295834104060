
class ConfirmSetStatusToPrinted extends ConfirmDialog {
    private switchId: string;
    private switchTypeId: string;

    constructor(header:string, content:string, switchId:string, switchTypeId:string, triggerGlobal = true, successCallback: Function = null) {
        super(header, content, triggerGlobal, successCallback);
        this.switchId = switchId;
        this.switchTypeId = switchTypeId;
        this.bindHandlers();

    }
  
    bindHandlers() {
        this.setStatusToPrinted.bind(this);
        $(document).on("click", "#btnConfirm", this.setStatusToPrinted.bind(this));
    }

    public setStatusToPrinted(e: Event) {
        this.modalSelector.modal('hide');
        var url = "/Client2/Switch/PrintSwitchForm";

        var self = this;
        $.ajax({
            type: "POST",
            global: self.triggerGlobal,
            url: url,
            data: { SwitchId: self.switchId },
            success:  (data:any) => {
                if (self.successCallback != null) {
                    self.successCallback(data);
                }
                pdfSwitchViewer.showPdfInNewTab(self.switchId);
            },
            error: function (xhr, ajaxOptions, thrownError) {
                showAjaxStatusWindow('#WebResources_CLICKSWITCH_ERROR', thrownError, false);
            }
        });
    }

}